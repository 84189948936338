import axios from 'axios';

import { STORAGE_TOKEN_KEYS as K } from 'helpers/constants';

import { getFromStorage, saveTokens, clearStorage } from 'services/storage';

// Jade add
import Cookies from 'js-cookie';
// End add

const baseURL = process.env.REACT_APP_BASE_API;

const http = axios.create({
  baseURL,
});

export const refreshAuthTokenIfNeeded = async () => {
  const authTokenExpirationDate = getFromStorage(K.AUTH_TOKEN_EXPIRATION);
  if (authTokenExpirationDate >= Date.now()) {
    return true;
  }

  const user = getFromStorage(K.USER);
  if (!user) {
    throw Error('Please, reload the page.');
  }
  const { username } = user;
  const oldAuthToken = getFromStorage(K.AUTH_TOKEN);
  const oldRefreshToken = getFromStorage(K.REFRESH_TOKEN);
  const oldRefreshExpiresUtc = getFromStorage(K.REFRESH_TOKEN_EXPIRATION);
  try {
    const {
      data: { AuthenticationToken, User, RefreshToken, RefreshExpiresUtc },
    } = await http.post(
      '/.auth/RefreshToken',
      {
        username,
        authenticationToken: oldAuthToken,
        refreshToken: oldRefreshToken,
        refreshExpiresUtc: oldRefreshExpiresUtc,
      },
      { headers: { checkAuthorization: false } },
    );
    saveTokens(AuthenticationToken, RefreshToken, RefreshExpiresUtc, User);
    http.defaults.headers.common.Authorization = `Bearer ${AuthenticationToken}`;
    return true;
  } catch (error) {
    if (error.response.status === 401) {
      clearStorage();
      return false;
    }
  }
  return false;
};

// Jade add code to create cookie

// const _versionNo = Cookies.get('version');

const apiVersion = process.env.REACT_APP_API_VERSION;
const clientType = process.env.REACT_APP_CLIENT_TYPE;

/* alert(process.env.REACT_APP_API_VERSION)
if(_versionNo === null){
  const apiVersion = process.env.REACT_APP_API_VERSION;
}
else{
  const apiVersion = _versionNo;
}
/end add code cookie */

const beforeRequest = async ({ headers: { checkAuthorization = true, ...restHeaders }, ...rest }) => {
  // eslint-disable-next-line no-underscore-dangle
  const _versionNo = Cookies.get('version') ?? apiVersion;
  // debugger;
  // alert(1);
  let baseHeaders = {
    'x-client-type': clientType,
  };
  baseHeaders = {
    ...baseHeaders,
    'x-api-version': _versionNo === '052020' ? '2.0' : _versionNo,
  };
  /* if (apiVersion) {
    baseHeaders = {
      ...baseHeaders,
      'x-api-version': _versionNo,
    };
  } */
  const config = { ...rest, headers: { ...rest.headers, ...restHeaders, ...baseHeaders } };

  if (checkAuthorization) {
    await refreshAuthTokenIfNeeded();
    const authToken = getFromStorage(K.AUTH_TOKEN);
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  return config;
};

http.interceptors.request.use(beforeRequest);

export { http };
