const initialState = {
  isAuthenticated: false,
};

export const authenticate = state => ({
  ...state,
  isAuthenticated: true,
});

export default initialState;
