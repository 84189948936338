import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES as R } from 'helpers/constants';
import { useAsync } from 'react-use';
import { actions } from 'redux/customerApp/auth';
import { login } from 'http/auth';
import { saveTokens, clearStorage } from 'services/storage';

const StorePassword = process.env.REACT_APP_STOREPASSWORD;
const StoreUsername = process.env.REACT_APP_STOREUSERNAME;

const AppRoute = lazy(() => import('./AppRouter'));
const Recertification = lazy(() => import('../pages/Recertification'));

const RootRouter = () => {
  const dispatch = useDispatch();

  useAsync(async () => {
    clearStorage();
    const { AuthenticationToken, RefreshToken, RefreshExpiresUtc, User } = await login({
      username: StoreUsername,
      password: StorePassword,
    });

    saveTokens(AuthenticationToken, RefreshToken, RefreshExpiresUtc, User);
    dispatch(actions.authenticate(User));
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={`${R.RECERTIFICATION}/:STORE_NO/:SEQ_NO/:PIN`} component={Recertification} isAuthenticated />
          <Route path="/" component={AppRoute} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default RootRouter;
