const initialState = {
  step1: {
    storage: null,
    e4473n: null,
    pin: null,
    e4473: null,
    e4473Serials: null,
  },
  listOfStates: null,
  verify: {
    verifyName: null,
  },
  step3: {
    firstName: null,
    middleName: null,
    lastName: null,
    suffix: null,
  },
  step5: {
    address: null,
    addressExtend: null,
    city: null,
    cityLimits: null,
    county: null,
    state: '',
    zipCode: null,
  },
  step6: {
    bornInUSA: null,
  },
  step7_1: {
    city: null,
    state: '',
  },
  step7_2: {
    foreignCountryOfBirth: null,
  },
  step8: {
    heightFeet: null,
    heightInches: null,
    weight: null,
  },
  step9: {
    gender: null,
    dateOfBirth: null,
    ssn: null,
  },
  step10: {
    upin: null,
  },
  step11: {
    ethnicity: null,
  },
  step12: {
    american: false,
    asian: false,
    black: false,
    hawaiin: false,
    white: false,
  },
  step13: {
    citizenship: null,
  },
  step14: {
    citizenship: null,
  },
  step15: {
    alienNumber: null,
  },
  step16: {
    answer: null,
  },
  step17a: {
    answer: null,
  },
  step17b: {
    answer: null,
  },
  step17: {
    answer: null,
  },
  step18: {
    answer: null,
  },
  step19: {
    answer: null,
  },
  step20: {
    answer: null,
  },
  step21: {
    answer: null,
  },
  step22: {
    answer: null,
  },
  step23: {
    answer: null,
  },
  step24: {
    answer: null,
  },
  step25: {
    answer: null,
  },
  step26: {
    answer: null,
    answer_1: null,
    answer_2: null,
  },
  step27: {
    answer: null,
    answer_1: null,
    answer_2: null,
  },
  contacts: {
    email: null,
    phone: null,
  },
  sectionBLockFlag: false,
  store: null,
};

// eslint-disable-next-line camelcase
export const clearStep7_1 = state => ({
  ...state,
  step7_1: {
    city: null,
    state: null,
  },
});

// eslint-disable-next-line camelcase
export const clearStep7_2 = state => ({
  ...state,
  step7_2: {
    foreignCountryOfBirth: null,
  },
});

// eslint-disable-next-line camelcase
export const clearStep14_15 = state => ({
  ...state,
  step14: {
    citizenship: null,
  },
  step15: {
    alienNumber: null,
  },
});

export const clearStore = () => ({
  ...initialState,
});

export const setListOfStates = (state, { payload }) => ({
  ...state,
  listOfStates: payload,
});

export const setStore = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const setStepData = (state, { payload }) => ({
  ...state,
  [payload.stepName]: payload,
});

export default initialState;
