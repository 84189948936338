export const COUNTRY = 'country';

export const createRequiredText = text => `Enter ${text}`;
export const createLessThanText = num => `Use more then ${num} symbols`;
export const createMoreThanText = num => `Use less then ${num} symbols`;

export const STORE = 'store';
export const E4473 = 'e4473';
export const PIN = 'pin';
export const ONLY_NUMBERS = 'Please use only positive numbers';
export const FIRST_NAME = 'first name';
export const LAST_NAME = 'last name';
export const MIDDLE_NAME = 'middle name';
export const SUFFIX = 'suffix';
export const EMPTY_FIRST_NAME = 'You must provide a first name';
export const EMPTY_LAST_NAME = 'You must provide a last name';
export const EMPTY_MIDDLE_NAME =
  'You must provide a middle name.  If you do not have a middle name, enter NMN into this field (which stands for “no middle name)';
export const ADDRESS = 'address';
export const CITY = 'city';
export const CITY_LIMITS = 'City Limits';
export const RESIDE_CITY_LIMITS = { yes: 'yes', no: 'no', unknown: 'unknown' };
export const COUNTY_PARISH_BOROUGH = 'County/Parish/Borough';
export const STATE = 'state';
export const ZIP_CODE = 'zip code';
export const COUNTY_PARISH_BOROUGH_ERROR =
  'You entered a country into the county/parish/borough field.  Please enter a county, parish, or borough instead.';
export const createBetweenText = (field, min, max, units) => `${field} must be between ${min} - ${max} ${units}.`;
export const HEIGHT = 'Height';
export const WEIGHT = 'Weight';
export const FEETS = 'feet';
export const INCHES = 'inches';
export const LBS = 'lbs';
export const DATE_OF_BIRTH = 'Date of Birth';
export const PLACE_OF_BIRTH = 'Place of Birth';
export const GENDER = 'Gender';
export const DATE_OF_BIRTH_ERROR_MESSAGE = 'You must be 18 to purchase a firearm';
export const DATE_OF_BIRTH_ERROR_MESSAGE_ACCURATE = 'The year of birth that you entered is not accurate';
export const ETHNICITY = 'ethnicity';
export const RACE = 'Race';
export const CITIZENSHIP = 'Citizenshhip';
export const EMAIL = 'email';
export const ONLY_LATIN = 'Please use only letters (a-z), numbers and special characters (!£$%^&*?@#._, -+=)';
export const NO_SPACES = 'Please do not use spaces';
export const INVALID_EMAIL = 'Enter a valid email';

export const ANSWER = {
  yes: 'Y',
  no: 'N',
  thirdOption: '',
  // changed thirdOption from 3rd to '' to fix SQL error when trying to save through the API
};

export const ANSWERV2 = {
  yes: 'Y',
  no: 'N',
  thirdOption: 'U',
  // changed thirdOption from 3rd to '' to fix SQL error when trying to save through the API
};

export const GENDER_VALUES = { male: 'male', female: 'female', non_binary: 'non binary' };
export const INSTRUCTION_URL = 'https://rapid4473usstorage.blob.core.windows.net/rapid-e4473-files/4473upd.pdf';
export const INSTRUCTION_URL_042023 =
  'https://rapid4473usstorage.blob.core.windows.net/rapid-e4473-files/4473_v042023.pdf';
export const INSTRUCTION_URL_202308 =
  'https://rapid4473usstorage.blob.core.windows.net/rapid-e4473-files/f_5300.9_4473_v202308_pdf_doc.pdf';
export const INVALID_STATE_NAME = 'These characters are not a valid state nor territory';
export const NOT_REVIEWED = 'NOT REVIEWED';
export const CUSTOMER_ERROR = 'CUSTOMER ERROR';
export const NOT_SIGNED = 'NOT SIGNED';
export const RECERTIFY = 'RECERTIFY';
export const SIGNED = 'SIGNED';
export const TERMINATED = 'TERMINATED';
export const DENIED = 'DENIED';
export const COMPLETED = 'COMPLETED';
export const TRANSFERCOMPLETE = 'TRANSFER COMPLETE';
export const shortDateFormat = 'yyyy-MM-dd';
