import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import steps from './steps';
import auth from './auth';

const rootReducer = combineReducers({
  steps,
  auth,
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
