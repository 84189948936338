import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import './i18n';
import store from 'redux/customerApp/reducers';

import GlobalStyle from 'components/GlobalStyle';
import RootRouter from './router';

ReactDOM.render(
  <>
    <GlobalStyle />
    <ReduxProvider store={store}>
      <RootRouter />
    </ReduxProvider>
  </>,
  document.getElementById('root'),
);
