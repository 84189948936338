export const ROUTES = {
  MAIN_ROUTE: '/main',
  DETAILS_ROUTE: '/main/details',
  HOME_PAGE_ROUTE: '/home',
  LEARN_MORE_ROUTE: '/home/about',
  BEGIN_ROUTE: '/e4473',
  RECERTIFICATION: '/recertification',
  SUBMIT: '/submit',
  CONTACTS: '/contacts',
};
