import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const steps = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    clearStep7_1: handlers.clearStep7_1,
    clearStep7_2: handlers.clearStep7_2,
    clearStep14_15: handlers.clearStep14_15,
    clearStore: handlers.clearStore,
    setStore: handlers.setStore,
    setListOfStates: handlers.setListOfStates,
    setStepData: handlers.setStepData,
  },
});

export const { actions } = steps;

export default steps.reducer;
